var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-order",
      attrs: {
        width: _vm.isMobileScreen ? "90%" : "80%",
        title: _vm.isUnlink ? "Unlink Order" : "Link Order",
        visible: _vm.dialogLinkOrder,
      },
      on: {
        close: _vm.handleLinkOrderDialogClose,
        open: _vm.handleLinkOrderDialogOpen,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isOrderTableLoading,
              expression: "isOrderTableLoading",
            },
          ],
          class: _vm.isUnlink ? "div-unlink-order" : "div-link-order",
        },
        [
          !_vm.isOrderTableLoading
            ? _c("table-order", {
                attrs: {
                  hasPagination: !_vm.isUnlink,
                  orderPagination: _vm.orderPagination,
                  hasRadio: !_vm.isUnlink,
                  isOrderTableLoading: _vm.isOrderTableLoading,
                  orderType: "DELIVERED_AND_BARGING_NOMINATION",
                  orderTableData: _vm.linkOrderTableData,
                },
                on: {
                  handleOrderPageChange: _vm.handleOrderPageChange,
                  handleOrderPageSizeChange: _vm.handleOrderPageSizeChange,
                  handleOrderRadioSelectChange:
                    _vm.handleOrderRadioSelectChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleLinkOrderDialogClose },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !_vm.linkedOrderSelected,
              },
              on: { click: _vm.handleConfirmLinkOrderClick },
            },
            [_vm._v(" Confirm ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }